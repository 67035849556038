import React from 'react';
import "./home-header.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick/lib";
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const HomeHeader = () => {

    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
    };

    const { t, i18n } = useTranslation();

    return (
        <div className="Home-header">
            <div className="top">
                <div className="container">

                    <Fade left duration={1000}>
                        <div className="title">
                            {t("homeHeader.title1")} <br/>
                            {t("homeHeader.title2")} <br/>
                            {t("homeHeader.title3")} <br/>
                            {t("homeHeader.title4")}
                        </div>
                    </Fade>

                    <Fade bottom duration={1000}>
                        <div className="commit">
                            {t("homeHeader.commit1")} <br/>
                            {t("homeHeader.commit2")}
                        </div>
                    </Fade>

                </div>
            </div>
            <div className="bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-3">

                            <Fade left duration={1000}>

                                <div className="head-card">
                                    {i18n.language === 'ru' ?

                                            <div className="text">
                                                {t("homeHeader.headCard.card1.text1")}
                                                {t("homeHeader.headCard.card1.text2")}
                                                <span> {t("homeHeader.headCard.card1.text3")} </span>
                                            </div>
                                         :

                                        i18n.language === 'uz' ?

                                            <div className="text">
                                                {t("homeHeader.headCard.card1.text1")}
                                                <span> {t("homeHeader.headCard.card1.text2")} </span>
                                                {t("homeHeader.headCard.card1.text3")}
                                            </div> :

                                            <div className="text">
                                                {t("homeHeader.headCard.card1.text1")}
                                                <span> {t("homeHeader.headCard.card1.text2")} </span>
                                                {t("homeHeader.headCard.card1.text3")}
                                            </div>

                                    }
                                </div>

                            </Fade>

                        </div>
                        <div className="col-3">

                            <Fade bottom duration={1000}>
                                <div className="head-card">
                                    {i18n.language === 'ru' ?

                                        <div className="text">
                                            <span>{t("homeHeader.headCard.card2.text1")}</span>
                                            {t("homeHeader.headCard.card2.text2")}
                                            {t("homeHeader.headCard.card2.text3")}
                                        </div>
                                        :

                                        i18n.language === 'uz' ?

                                            <div className="text">
                                                {t("homeHeader.headCard.card2.text1")}
                                                <span> {t("homeHeader.headCard.card2.text2")} </span>
                                                {t("homeHeader.headCard.card2.text3")}
                                            </div> :

                                            <div className="text">
                                                {t("homeHeader.headCard.card2.text1")}
                                                <span> {t("homeHeader.headCard.card2.text2")} </span>
                                                {t("homeHeader.headCard.card2.text3")}
                                            </div>
                                    }
                                </div>
                            </Fade>

                        </div>
                        <div className="col-3">

                            <Fade bottom duration={1000}>
                                <div className="head-card">
                                    {i18n.language === 'ru' ?

                                        <div className="text">
                                            <span>{t("homeHeader.headCard.card3.text1")}</span>
                                            {t("homeHeader.headCard.card3.text2")}
                                            {t("homeHeader.headCard.card3.text3")}
                                        </div>
                                        :

                                        i18n.language === 'uz' ?

                                            <div className="text">
                                                {t("homeHeader.headCard.card3.text1")}
                                                <span> {t("homeHeader.headCard.card3.text2")} </span>
                                                {t("homeHeader.headCard.card3.text3")}
                                            </div> :

                                            <div className="text">
                                                {t("homeHeader.headCard.card3.text1")}
                                                <span> {t("homeHeader.headCard.card3.text2")} </span>
                                                {t("homeHeader.headCard.card3.text3")}
                                            </div>
                                    }
                                </div>
                            </Fade>

                        </div>
                        <div className="col-3">
                            <Fade right duration={1000}>
                                <div className="head-card">
                                    {i18n.language === 'ru' ?

                                        <div className="text">
                                            <span>{t("homeHeader.headCard.card4.text1")}</span>
                                            {t("homeHeader.headCard.card4.text2")}
                                            {t("homeHeader.headCard.card4.text3")}
                                        </div>
                                        :

                                        i18n.language === 'uz' ?

                                            <div className="text">
                                                {t("homeHeader.headCard.card4.text1")}
                                                <span> {t("homeHeader.headCard.card4.text2")} </span>
                                                {t("homeHeader.headCard.card4.text3")}
                                            </div> :

                                            <div className="text">
                                                {t("homeHeader.headCard.card4.text1")}
                                                <span> {t("homeHeader.headCard.card4.text2")} </span>
                                                {t("homeHeader.headCard.card4.text3")}
                                            </div>
                                    }
                                </div>
                            </Fade>

                        </div>
                    </div>
                </div>
            </div>

            <div className="carousel-box">
                <div className="container">
                    <Slider {...settings}>
                        <div className="Carousel-item">
                            <div className="head-card">
                                {i18n.language === 'ru' ?

                                    <div className="text">
                                        {t("homeHeader.headCard.card1.text1")}
                                        {t("homeHeader.headCard.card1.text2")}
                                        <span> {t("homeHeader.headCard.card1.text3")} </span>
                                    </div>
                                    :

                                    i18n.language === 'uz' ?

                                        <div className="text">
                                            {t("homeHeader.headCard.card1.text1")}
                                            <span> {t("homeHeader.headCard.card1.text2")} </span>
                                            {t("homeHeader.headCard.card1.text3")}
                                        </div> :

                                        <div className="text">
                                            {t("homeHeader.headCard.card1.text1")}
                                            <span> {t("homeHeader.headCard.card1.text2")} </span>
                                            {t("homeHeader.headCard.card1.text3")}
                                        </div>

                                }
                            </div>
                        </div>

                        <div className="Carousel-item">
                            <div className="head-card">
                                {i18n.language === 'ru' ?

                                    <div className="text">
                                        <span>{t("homeHeader.headCard.card2.text1")}</span>
                                        {t("homeHeader.headCard.card2.text2")}
                                        {t("homeHeader.headCard.card2.text3")}
                                    </div>
                                    :

                                    i18n.language === 'uz' ?

                                        <div className="text">
                                            {t("homeHeader.headCard.card2.text1")}
                                            <span> {t("homeHeader.headCard.card2.text2")} </span>
                                            {t("homeHeader.headCard.card2.text3")}
                                        </div> :

                                        <div className="text">
                                            {t("homeHeader.headCard.card2.text1")}
                                            <span> {t("homeHeader.headCard.card2.text2")} </span>
                                            {t("homeHeader.headCard.card2.text3")}
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="Carousel-item">
                            <div className="head-card">
                                {i18n.language === 'ru' ?

                                    <div className="text">
                                        <span>{t("homeHeader.headCard.card3.text1")}</span>
                                        {t("homeHeader.headCard.card3.text2")}
                                        {t("homeHeader.headCard.card3.text3")}
                                    </div>
                                    :

                                    i18n.language === 'uz' ?

                                        <div className="text">
                                            {t("homeHeader.headCard.card3.text1")}
                                            <span> {t("homeHeader.headCard.card3.text2")} </span>
                                            {t("homeHeader.headCard.card3.text3")}
                                        </div> :

                                        <div className="text">
                                            {t("homeHeader.headCard.card3.text1")}
                                            <span> {t("homeHeader.headCard.card3.text2")} </span>
                                            {t("homeHeader.headCard.card3.text3")}
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="Carousel-item">
                            <div className="head-card">
                                {i18n.language === 'ru' ?

                                    <div className="text">
                                        <span>{t("homeHeader.headCard.card4.text1")}</span>
                                        {t("homeHeader.headCard.card4.text2")}
                                        {t("homeHeader.headCard.card4.text3")}
                                    </div>
                                    :

                                    i18n.language === 'uz' ?

                                        <div className="text">
                                            {t("homeHeader.headCard.card4.text1")}
                                            <span> {t("homeHeader.headCard.card4.text2")} </span>
                                            {t("homeHeader.headCard.card4.text3")}
                                        </div> :

                                        <div className="text">
                                            {t("homeHeader.headCard.card4.text1")}
                                            <span> {t("homeHeader.headCard.card4.text2")} </span>
                                            {t("homeHeader.headCard.card4.text3")}
                                        </div>
                                }
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

        </div>
    );
};

export default HomeHeader;