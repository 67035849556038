import React from 'react';
import animation from '../../assets/loading/data.json';
import Lottie from 'react-lottie';
import "./loading.scss";

const Loading = () => {

    const options= {
        loop: true,
        autoplay: true,
        prerender: true,
        animationData: animation
    };

    return (
        <div className="Loading">
            <Lottie options={options} segments={[0, 0]} />
        </div>
    );
};

export default Loading;