import React from 'react';
import "./new-horizons.scss";
import Icon1 from '../../assets/images/new-horizons/icon-1.png';
import Icon2 from '../../assets/images/new-horizons/icon-2.png';
import Icon3 from '../../assets/images/new-horizons/icon-3.png';
import Icon4 from '../../assets/images/new-horizons/icon-4.png';
import Icon5 from '../../assets/images/new-horizons/icon-5.png';
import Icon6 from '../../assets/images/new-horizons/icon-6.png';
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const NewHorizons = () => {

    const {t} = useTranslation();

    return (
        <div className="New-horizons">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <Fade left delay={500}>
                            <div className="title">
                                {t("newHorizons.title1")} <br/>
                                {t("newHorizons.title2")}
                            </div>
                        </Fade>
                    </div>
                    <div className="col-md-7">
                        <Fade right delay={1000}>
                            <div className="commit">
                                {t("newHorizons.commit")}
                            </div>
                        </Fade>
                    </div>
                </div>
                
                <div className="horizons-box">
                    <div className="row">

                        <div className="col-md-4">
                            <Fade left delay={500}>
                                <a href="https://grandpharm.uz/" target="_blank" className="horizon-card">
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="info-box">
                                                <div className="card-title">
                                                    {t("newHorizons.horizonsBox.box1.title")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="img-box">
                                                <img className="img-fluid" src={Icon1} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </Fade>
                        </div>

                        <div className="col-md-4">
                            <Fade bottom delay={500}>
                                <a href="https://grandpharm.uz/maps" target="_blank" className="horizon-card bg-gradient-box">
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="info-box">
                                                <div className="card-title">
                                                    {t("newHorizons.horizonsBox.box2.title")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="img-box">
                                                <img className="img-fluid" src={Icon2} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </Fade>
                        </div>

                        <div className="col-md-4">
                            <Fade right delay={500}>
                                <a href="https://gopharm.uz/" target="_blank" className="horizon-card">
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="info-box">
                                                <div className="card-title">
                                                    {t("newHorizons.horizonsBox.box3.title")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="img-box">
                                                <img className="img-fluid" src={Icon3} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </Fade>
                        </div>

                        <div className="col-md-4">
                            <Fade left delay={500}>
                                <a href="https://grandmarketing.uz/" target="_blank" className="horizon-card bg-gradient-box">
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="info-box">
                                                <div className="card-title">
                                                    {t("newHorizons.horizonsBox.box4.title")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="img-box">
                                                <img className="img-fluid" src={Icon4} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </Fade>
                        </div>

                        <div className="col-md-4">
                            <Fade bottom delay={500}>
                                <a href="https://www.gpacademy.uz/" target="_blank" className="horizon-card">
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="info-box">
                                                <div className="card-title">
                                                    {t("newHorizons.horizonsBox.box5.title")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="img-box">
                                                <img className="img-fluid" src={Icon5} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </Fade>
                        </div>

                        <div className="col-md-4">
                            <Fade right delay={500}>
                                <div className="horizon-card bg-gradient-box">
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="info-box">
                                                <div className="card-title">
                                                    {t("newHorizons.horizonsBox.box6.title")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="img-box">
                                                <img className="img-fluid" src={Icon6} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewHorizons;