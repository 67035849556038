import React from 'react';
import "./social-networks.scss";
import Telegram from "../../assets/images/social-networks/telegram.png";
import Instagram from "../../assets/images/social-networks/instagram.png";
import Facebook from "../../assets/images/social-networks/facebook.png";
import Youtube from "../../assets/images/social-networks/youtube.png";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import {useTranslation} from "react-i18next";

const SocialNetworks = () => {

    const {t} = useTranslation();

    return (

        <div className="Social-networks">

            <div className="container">

                <div className="row">
                    <div className="col-md-12">
                        <Fade bottom delay={500}>
                            <div className="title">
                                {t("socialNetworks.title")}
                            </div>
                        </Fade>
                    </div>
                </div>

                <div className="row networks">
                    <Bounce duration={2500} left dealy={500}>
                        <div className="col-md-3 col-6">
                            <div className="network-card">
                                <div className="network-title">
                                    Telegram
                                </div>
                                <div className="network-image">
                                    <LazyLoadImage
                                        alt={""}
                                        effect="blur"
                                        height={""}
                                        src={Telegram}
                                        className="img-fluid"
                                    />
                                </div>
                                <a href="https://t.me/aptekagrandpharm" target="_blank" className="network-link">
                                    {t("socialNetworks.buttonText")}
                                </a>
                            </div>
                        </div>
                    </Bounce>
                    <Bounce duration={2500} bottom dealy={500}>
                        <div className="col-md-3 col-6">
                            <div className="network-card">
                                <div className="network-title">
                                    Instagram
                                </div>
                                <div className="network-image">
                                    <LazyLoadImage
                                        alt={""}
                                        effect="blur"
                                        height={""}
                                        src={Instagram}
                                        className="img-fluid"
                                    />
                                </div>
                                <a href="https://www.instagram.com/grandpharmtrade/" target="_blank" className="network-link">
                                    {t("socialNetworks.buttonText")}
                                </a>
                            </div>
                        </div>
                    </Bounce>
                    <Bounce duration={2500} bottom dealy={500}>
                        <div className="col-md-3 col-6">
                            <div className="network-card">
                                <div className="network-title">
                                    Facebook
                                </div>
                                <div className="network-image">
                                    <LazyLoadImage
                                        alt={""}
                                        effect="blur"
                                        height={""}
                                        src={Facebook}
                                        className="img-fluid"
                                    />
                                </div>
                                <a href="https://www.facebook.com/profile.php?id=100072414248073" target="_blank" className="network-link">
                                    {t("socialNetworks.buttonText")}
                                </a>
                            </div>
                        </div>
                    </Bounce>
                    <Bounce duration={2500} right dealy={500}>
                        <div className="col-md-3 col-6">
                            <div className="network-card">
                                <div className="network-title">
                                    Youtube
                                </div>
                                <div className="network-image">
                                    <LazyLoadImage
                                        alt={""}
                                        effect="blur"
                                        height={""}
                                        src={Youtube}
                                        className="img-fluid"
                                    />
                                </div>
                                <a href="https://www.youtube.com/channel/UCVeD8Kd3fOe2SNUjJ8rlQ9Q" target="_blank" className="network-link">
                                    {t("socialNetworks.buttonText")}
                                </a>
                            </div>
                        </div>
                    </Bounce>
                </div>


            </div>
        </div>
    );
};

export default SocialNetworks;