import React from 'react';
import "./full-range-of-services.scss";
import LeftImage from "../../assets/images/full-range-of-services/left-image.png";
import Icon1 from "../../assets/images/full-range-of-services/icon-1.png";
import Icon2 from "../../assets/images/full-range-of-services/icon-2.png";
import Icon3 from "../../assets/images/full-range-of-services/icon-3.png";
import Icon4 from "../../assets/images/full-range-of-services/icon-4.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const FullRangeOfServices = () => {

    const { t, i18n } = useTranslation();

    return (
        <div className="Full-range-of-services">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="left-box">
                            <LazyLoadImage
                                alt={""}
                                effect="blur"
                                height={""}
                                src={LeftImage}
                                width={""}
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="right-box">
                            <div className="content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="title">
                                            {t("fullRangeOfServices.title1")} <br/>
                                            {t("fullRangeOfServices.title2")}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <Fade left delay={500}>
                                            <div className="service-box">
                                                <div className="img-box">
                                                    <img src={Icon1} alt=""/>
                                                </div>
                                                <div className="service-title">
                                                    {t("fullRangeOfServices.services.service1.title")}
                                                </div>
                                                <div className="service-commit">
                                                    {t("fullRangeOfServices.services.service1.commit")}
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>

                                    <div className="col-6">
                                        <Fade right delay={600}>
                                            <div className="service-box">
                                                <div className="img-box">
                                                    <img src={Icon2} alt=""/>
                                                </div>
                                                <div className="service-title">
                                                    {t("fullRangeOfServices.services.service2.title")}
                                                </div>
                                                <div className="service-commit">
                                                    {t("fullRangeOfServices.services.service2.commit")}
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>

                                    <div className="col-6">
                                        <Fade left delay={700}>
                                            <div className="service-box">
                                                <div className="img-box">
                                                    <img src={Icon3} alt=""/>
                                                </div>
                                                <div className="service-title">
                                                    {t("fullRangeOfServices.services.service3.title")}
                                                </div>
                                                <div className="service-commit">
                                                    {t("fullRangeOfServices.services.service3.commit")}
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>

                                    <div className="col-6">
                                        <Fade right delay={800}>
                                            <div className="service-box">
                                                <div className="img-box">
                                                    <img src={Icon4} alt=""/>
                                                </div>
                                                <div className="service-title">
                                                    {t("fullRangeOfServices.services.service4.title")}
                                                </div>
                                                <div className="service-commit">
                                                    {t("fullRangeOfServices.services.service4.commit")}
                                                </div>
                                            </div>
                                        </Fade>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FullRangeOfServices;