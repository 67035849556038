import React from 'react';
import "./Footer.scss";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FooterLogo from '../../assets/images/footer/footer-logo.svg';
import Facebook from '../../assets/images/footer/facebook.svg';
import Instagram from '../../assets/images/footer/instagram.svg';
import Telegram from '../../assets/images/footer/telegram.svg';
import Youtube from '../../assets/images/footer/youtube.svg';

const Footer = () => {

    const {t} = useTranslation();

    const menu = [
        {
            title: t("menu.home"),
            path: "/home",
        },
        {
            title: t("menu.about"),
            path: "/about",
        },
        {
            title: t("menu.services"),
            path: "/services",
        },
        /*{
            title: t("menu.prices"),
            path: "/prices",
        },*/
        {
            title: t("menu.contact"),
            path: "/contact",
        }
    ];

    return (
        <div className="Footer">
            <div className="container">
                <div className="top">
                    <div className="row menu-box">
                        <div className="col-md-3">
                            <NavLink to="/home">
                                <img src={FooterLogo} alt=""/>
                            </NavLink>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                {
                                    menu.map((item, index) => (
                                        <div key={index} className="col-md-3 col-6">
                                            <NavLink activeClassName="active-menu" to={item.path}>{item.title}</NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row contact-box">
                        <div className="col-md-3">
                            <div className="contact">
                                <a href="tel:+998957678400" className="info">
                                    {t("footer.contacts.info1.info")}
                                </a>

                                <div className="commit">
                                    {t("footer.contacts.info1.commit")}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="contact">
                                <a href="tel:+998909778549" className="info">
                                    {t("footer.contacts.info2.info")}
                                </a>
                                <div className="commit">
                                    {t("footer.contacts.info2.commit")}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="contact-link">
                                <a href="https://www.facebook.com/profile.php?id=100072414248073" target="_blank">
                                    <img src={Facebook} alt=""/>
                                </a>
                                <a href="https://www.instagram.com/grandpharmtrade/" target="_blank">
                                    <img src={Instagram} alt=""/>
                                </a>
                                <a href="https://t.me/aptekagrandpharm" target="_blank">
                                    <img src={Telegram} alt=""/>
                                </a>
                                <a href="https://www.youtube.com/channel/UCVeD8Kd3fOe2SNUjJ8rlQ9Q" target="_blank">
                                    <img src={Youtube} alt=""/>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="contact-map">
                                <div className="name">
                                    {t("footer.name")}
                                </div>
                                <div className="address">
                                    {t("footer.address")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="top-text">
                                {t("footer.bottom.topText")}
                            </div>
                            <div className="bottom-text">
                                {t("footer.bottom.bottomText")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;