import React from 'react';
import "./services-and-ready-solutions.scss";
import Icon1 from "../../assets/images/services-and-ready-solutions/icon-1.png";
import Icon2 from "../../assets/images/services-and-ready-solutions/icon-2.png";
import Icon3 from "../../assets/images/services-and-ready-solutions/icon-3.png";
import Icon4 from "../../assets/images/services-and-ready-solutions/icon-4.png";
import Icon5 from "../../assets/images/services-and-ready-solutions/icon-5.png";
import Icon6 from "../../assets/images/services-and-ready-solutions/icon-6.png";
import Icon7 from "../../assets/images/services-and-ready-solutions/icon-7.png";
import Icon8 from "../../assets/images/services-and-ready-solutions/icon-8.png";
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const ServicesAndReadySolutions = () => {

    const {t} = useTranslation();

    return (
        <div className="Services-and-ready-solutions">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <Fade left delay={500}>
                            <div className="title">
                                {t("servicesAndReadySolutions.title1")} <br/>
                                {t("servicesAndReadySolutions.title2")}
                            </div>
                        </Fade>
                        <Fade bottom delay={1000}>
                            <div className="commit">
                                {t("servicesAndReadySolutions.commit")}
                            </div>
                        </Fade>
                    </div>
                </div>

                <div className="row services">
                    <Fade left delay={500}>
                        <div className="col-md-6">
                            <div className="service-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info-box">
                                            <div className="service-title">
                                                {t("servicesAndReadySolutions.services.box1.title1")} <br/>
                                                {t("servicesAndReadySolutions.services.box1.title2")}
                                            </div>
                                            <div className="service-commit">
                                                {t("servicesAndReadySolutions.services.box1.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon1} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade right delay={500}>
                        <div className="col-md-6">
                            <div className="service-box bg-gradient-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info-box">
                                            <div className="service-title">
                                                {t("servicesAndReadySolutions.services.box2.title1")} <br/>
                                                {t("servicesAndReadySolutions.services.box2.title2")}
                                            </div>
                                            <div className="service-commit">
                                                {t("servicesAndReadySolutions.services.box2.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon2} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade left delay={500}>
                        <div className="col-md-6">
                            <div className="service-box bg-gradient-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info-box">
                                            <div className="service-title">
                                                {t("servicesAndReadySolutions.services.box3.title1")} <br/>
                                                {t("servicesAndReadySolutions.services.box3.title2")}
                                            </div>
                                            <div className="service-commit">
                                                {t("servicesAndReadySolutions.services.box3.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon3} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade right delay={500}>
                        <div className="col-md-6">
                            <div className="service-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info-box">
                                            <div className="service-title">
                                                {t("servicesAndReadySolutions.services.box4.title1")} <br/>
                                                {t("servicesAndReadySolutions.services.box4.title2")}
                                            </div>
                                            <div className="service-commit">
                                                {t("servicesAndReadySolutions.services.box4.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon4} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade left delay={500}>
                        <div className="col-md-6">
                            <div className="service-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info-box">
                                            <div className="service-title">
                                                {t("servicesAndReadySolutions.services.box5.title1")} <br/>
                                                {t("servicesAndReadySolutions.services.box5.title2")}
                                            </div>
                                            <div className="service-commit">
                                                {t("servicesAndReadySolutions.services.box5.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon5} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade right delay={500}>
                        <div className="col-md-6">
                            <div className="service-box bg-gradient-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info-box">
                                            <div className="service-title">
                                                {t("servicesAndReadySolutions.services.box6.title1")} <br/>
                                                {t("servicesAndReadySolutions.services.box6.title2")}
                                            </div>
                                            <div className="service-commit">
                                                {t("servicesAndReadySolutions.services.box6.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon6} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade left delay={500}>
                        <div className="col-md-6">
                            <div className="service-box bg-gradient-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info-box">
                                            <div className="service-title">
                                                {t("servicesAndReadySolutions.services.box7.title1")} <br/>
                                                {t("servicesAndReadySolutions.services.box7.title2")}
                                            </div>
                                            <div className="service-commit">
                                                {t("servicesAndReadySolutions.services.box7.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon7} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                    <Fade right delay={500}>
                        <div className="col-md-6">
                            <div className="service-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info-box">
                                            <div className="service-title">
                                                {t("servicesAndReadySolutions.services.box8.title1")} <br/>
                                                {t("servicesAndReadySolutions.services.box8.title2")}
                                            </div>
                                            <div className="service-commit">
                                                {t("servicesAndReadySolutions.services.box8.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon8} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                </div>

            </div>
        </div>
    );
};

export default ServicesAndReadySolutions;