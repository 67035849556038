import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import Navbar from "../../components/Navbar/Navbar";
import GrandLogisticArea from "../../components/GrandLogisticArea/GrandLogisticArea";
import FullRangeOfServices from "../../components/FullRangeOfServices/FullRangeOfServices";
import GrandLogisticModalVideo from "../../components/GrandLogisticModalVideo/GrandLogisticModalVideo";
import HowToOrderServices from "../../components/HowToOrderServices/HowToOrderServices";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import Loading from "../../components/Loading/Loading";

const Home = () => {

    useEffect(()=>{
        window.scroll(0,0);
    },[]);

    const { t, i18n } = useTranslation();

    // Languages
    const languages = [
        { name: "Русский", code: "ru" },
        { name: "Uzbekcha", code: "uz" },
        { name: "English", code: "en" }
    ];

    const currentLocale = Cookies.get("i18next") || "en";

    const currentLangObj = languages.find((lang) => lang.code === currentLocale);

    useEffect(() => {
        document.body.dir = currentLangObj.dir || "ltr";
        document.title = t("app_title");
    }, [currentLangObj, t]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    if (loading) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <Navbar/>
            <HomeHeader/>
            <GrandLogisticArea/>
            <GrandLogisticModalVideo/>
            <FullRangeOfServices/>
            <HowToOrderServices/>
            <Footer/>
        </>
    );
};

export default Home;
