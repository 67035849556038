import React, {useEffect, useState} from 'react';
import Navbar from "../../components/Navbar/Navbar";
import HowToOrderServices from "../../components/HowToOrderServices/HowToOrderServices";
import Footer from "../../components/Footer/Footer";
import AboutHeader from "../../components/AboutHeader/AboutHeader";
import LiderFarmaSevtika from "../../components/LiderFarmasevtika/LiderFarmaSevtika";
import NewHorizons from "../../components/NewHorizons/NewHorizons";
import ProfessionalFarmaseftika from "../../components/ProfessionalFarmaseftika/ProfessionalFarmaseftika";
import AboutNumberOfDorixona from "../../components/AboutNumberOfDorixona/AboutNumberOfDorixona";
import HealthClickAway from "../../components/HealthClickAway/HealthClickAway";
import Loading from "../../components/Loading/Loading";

const About = () => {

    useEffect(()=>{
        window.scroll(0,0);
    },[]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    if (loading) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <Navbar/>
            <AboutHeader/>
            <LiderFarmaSevtika/>
            <NewHorizons/>
            <ProfessionalFarmaseftika/>
            <AboutNumberOfDorixona/>
            <HealthClickAway/>
            <HowToOrderServices/>
            <Footer/>
        </>
    );
};

export default About;