import React, {useEffect, useRef, useState} from 'react';
import DesktopLogo from '../../assets/images/navbar/desktop-logo.svg';
import MobileLogo from '../../assets/images/navbar/mobile-logo.svg';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./Navbar.scss";
import Cookies from "js-cookie";
import Arrow from '../../assets/images/chevron-down.png';
import CurrencyFormat from "react-currency-format";
import {Modal, ModalBody} from 'reactstrap';
import Facebook from "../../assets/images/navbar/facebook.svg";
import Instagram from "../../assets/images/navbar/instagram.svg";
import Telegram from "../../assets/images/navbar/telegram.svg";
import Youtube from "../../assets/images/navbar/youtube.svg";
import emailjs from 'emailjs-com';
import {toast} from "react-toastify";
import Bounce from "react-reveal/Bounce";

const Navbar = () => {

        const [lang_open, setLangOpen] = useState(false);

        const [modal, setModal] = useState(false);
        const [show_menu, setShowMenu] = useState(false);

        const toggle = () => setModal(!modal);

        const {t, i18n} = useTranslation();

        const menu = [
            {
                title: t("menu.home"),
                path: "/home",
            },
            {
                title: t("menu.about"),
                path: "/about",
            },
            {
                title: t("menu.services"),
                path: "/services",
            },
            {
                title: t("menu.contact"),
                path: "/contact",
            }
        ];

        // Languages
        const languages = [
            {name: "RU", code: "ru"},
            {name: "UZ", code: "uz"},
            {name: "EN", code: "en"}
        ];

        const currentLocale = Cookies.get("i18next") || "ru";

        const [language, setLanguage] = useState(currentLocale);

        const handleChangeLocale = (lang) => {
            setLanguage(lang);
            setLangOpen(false);
            i18n.changeLanguage(lang);
        };

        const currentLangObj = languages.find((lang) => lang.code === currentLocale);

        useEffect(() => {
            document.body.dir = currentLangObj.dir || "ltr";
            document.title = t("app_title");
        }, [currentLangObj, t]);

        const [phone_value, setPhoneValue] = useState('');
        const [name_value, setNameValue] = useState('');
        const [organization_value, setOrganizationValue] = useState('');
        const [loading, setLoading] = useState(false);

        const onSubmit = (e) => {

            setLoading(loading => true);

            e.preventDefault();

            let name = e.target.name.value.length > 0;
            let organization = e.target.organization.value.length > 0;
            let phone = e.target.phone.value.replace(/[^0-9]/g, '').length > 0;

            if (name && phone && organization) {

                emailjs.sendForm(
                    'service_m3xrumf',
                    'template_j8e7ocr',
                    e.target,
                    'wqf5nQ9HFXwadl7oa'
                )

                    .then((res) => {
                        setNameValue('');
                        setOrganizationValue('');
                        setPhoneValue('');
                        toast.success(t("form.toastify.successMessage"));
                        setModal(false);
                        setLoading(loading => false);
                    })

                    .catch((err) => {
                        toast.error(t("form.toastify.errorMessage"));
                        setLoading(loading => false);
                    })

            } else {
                toast.warning(t("form.toastify.warningMessage"));
                setLoading(loading => false);
            }
        };

        const ref = useRef(null);

        const handleClickOutside = (event) => {
            if (lang_open === true) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setLangOpen(false);
                }
            }
        };

        useEffect(() => {
            document.addEventListener("click", handleClickOutside, true);
            return () => {
                document.removeEventListener("click", handleClickOutside, true);
            };
        });

        const setLangOpenFunc = () => {
            setLangOpen(!lang_open);
        };

        return (
            <div className="Navbar">

                <div className="desktop-navbar">

                    <div className="container">
                        <div className="content">

                            <div className="left-box">
                                <div className="logo-box">
                                    <NavLink to="/home">
                                        <img src={DesktopLogo} alt=""/>
                                    </NavLink>
                                </div>

                                <ul className="menu">
                                    {menu.map(({title, path}) => (
                                        <li className="menu-item" key={title}>
                                            <NavLink activeClassName="active-menu" to={path}>{title}</NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="right-box">
                                <button className="application-btn" onClick={() => setModal(true)}>{
                                    t("navbar.buttonText")}</button>
                                <div className="language-box">

                                    <div className="selected-language" onClick={() => setLangOpenFunc()}>
                                        <div className="text">{language}</div>
                                        <img className={`${lang_open === true ? "open" : "close"}`} src={Arrow} alt=""/>
                                    </div>

                                    <div ref={ref} className={`select ${lang_open === true ? "open-lang" : "close-lang"}`}>
                                        {languages.filter(item => item.code !== language).map(({name, code}) => (
                                            <div key={name} className="language" onClick={() => handleChangeLocale(code)}>{name}</div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="mobile-navbar">
                    <div className="container">
                        <div className="content">

                            <div className="left-box">
                                <div className="logo-box">
                                    <NavLink to="/home">
                                        <img src={MobileLogo} alt=""/>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="right-box">
                                <div className="language-box">
                                    <div className="selected-language" onClick={() => setLangOpen(!lang_open)}>
                                        <div className="text">{language}</div>
                                        <img className={`${lang_open === true ? "open" : "close"}`} src={Arrow} alt=""/>
                                    </div>
                                    <div ref={ref} className={`select ${lang_open === true ? "open-lang" : "close-lang"}`}>
                                        {languages.filter(item => item.code !== language).map(({name, code}) => (
                                            <div key={name} className="language" onClick={() => handleChangeLocale(code)}>{name}</div>
                                        ))}
                                    </div>
                                </div>

                                <div className={show_menu ? "menu-btn open" : "menu-btn"}
                                     onClick={() => setShowMenu(!show_menu)}>
                                    <div className="menu-btn__burger"></div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

                <div className={`mobile-menu ${!show_menu ? "close-menu" : "show-menu"}`}>
                    <div className="top">
                        <ul className="menu">
                            {menu.map(({title, path}) => (
                                <li className="menu-item" key={title}>
                                    <NavLink activeClassName="active-menu" to={path}>{title}</NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="bottom">
                        <div className="contact-box">
                            <a href="https://www.facebook.com/profile.php?id=100072414248073" target="_blank">
                                <img src={Facebook} alt=""/>
                            </a>
                            <a href="https://www.instagram.com/grandpharmtrade/" target="_blank">
                                <img src={Instagram} alt=""/>
                            </a>
                            <a href="https://t.me/aptekagrandpharm" target="_blank">
                                <img src={Telegram} alt=""/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCVeD8Kd3fOe2SNUjJ8rlQ9Q" target="_blank">
                                <img src={Youtube} alt=""/>
                            </a>
                        </div>

                        <button className="application-btn" onClick={() => {
                            setModal(true);
                            setShowMenu(false)
                        }}> {t("navbar.buttonText")}</button>
                    </div>

                </div>

                <Modal isOpen={modal} toggle={toggle} centered={true}>
                    <ModalBody>
                        <div className="form-title">{t("form.formTitle")}</div>
                        <form className="application-form" onSubmit={onSubmit}>

                            <input
                                value={name_value}
                                onChange={(e) => setNameValue(e.target.value)}
                                name="name"
                                type="text"
                                placeholder={t("form.placeholderName")}
                                className=""/>

                            <input
                                value={organization_value}
                                onChange={(e) => setOrganizationValue(e.target.value)}
                                name="organization"
                                type="text"
                                placeholder={t("form.placeholderOrganization")}
                                className=""/>

                            <CurrencyFormat
                                value={phone_value}
                                onChange={(e) => setPhoneValue(e.target.value)}
                                id="phone"
                                name="phone"
                                format="+### (##) ###-##-##" mask="_"
                                placeholder={t("form.placeholderPhone")}
                            />

                            <button disabled={loading} type="submit" className="form-btn">
                                {t("form.buttonText")}
                            </button>

                        </form>
                    </ModalBody>
                </Modal>

            </div>
        );
    }
;

export default Navbar;