import React, {useEffect, useState} from 'react';
import Navbar from "../../components/Navbar/Navbar";
import HowToOrderServices from "../../components/HowToOrderServices/HowToOrderServices";
import Footer from "../../components/Footer/Footer";
import ContactHeader from "../../components/ContactHeader/ContactHeader";
import OurContacts from "../../components/OurContacts/OurContacts";
import SocialNetworks from "../../components/SocialNetworks/SocialNetworks";
import HubOnTheMap from "../../components/HubOnTheMap/HubOnTheMap";
import Loading from "../../components/Loading/Loading";

const Contacts = () => {

    useEffect(()=>{
        window.scroll(0,0);
    },[]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    if (loading) {
        return (
            <Loading/>
        )
    };

    return (
        <>
            <Navbar/>
            <ContactHeader/>
            <OurContacts/>
            <SocialNetworks/>
            <HubOnTheMap/>
            <HowToOrderServices/>
            <Footer/>
        </>
    );
};

export default Contacts;