import React from 'react';
import "./lider-farmasevtika.scss";
import RightImage from "../../assets/images/lider-farmasevtika/lider-farmasevtika.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const LiderFarmaSevtika = () => {

    const {t} = useTranslation();

    return (
        <div className="Lider-farmasevtika">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="left-box">
                            <Fade left delay={500}>
                                <div className="title">
                                    {t("liderFarmaSevtika.title1")} <br/>
                                    {t("liderFarmaSevtika.title2")}
                                </div>
                            </Fade>
                            <Fade bottom delay={1000}>
                                <div className="commit">
                                    {t("liderFarmaSevtika.commit1")}
                                    <br/>
                                    <br/>
                                    {t("liderFarmaSevtika.commit2")}
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="right-box">
                            <LazyLoadImage
                                alt={""}
                                effect="blur"
                                height={""}
                                src={RightImage}
                                width={""}
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiderFarmaSevtika;