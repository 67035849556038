import React from 'react';
import "./contact-header.scss";
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const ContactHeader = () => {

    const {t} = useTranslation();

    return (
        <div className="Contact-header">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Fade left delay={500}>
                            <div className="title">
                                {t("contactHeader.title")}
                            </div>
                        </Fade>
                        <Fade bottom delay={1500}>
                            <div className="commit">
                                {t("contactHeader.commit")}
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactHeader;