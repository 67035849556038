import React, { useState } from "react";
import "./grand-logistic-modal-video.scss";
import PlayIcon from "../../assets/images/modal-video/play-icon.png";
import ModalVideo from "react-modal-video";
import FileRu from "../../assets/file/grand-log-catalogue-ru.pdf";
import FileUz from "../../assets/file/grand-log-catalogue-uz.pdf";
import FileEn from "../../assets/file/grand-log-catalogue-en.pdf";
import { useTranslation } from "react-i18next";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";

const GrandLogisticModalVideo = () => {
  const { t, i18n } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  let videos = {
    ru: "mPtKthDoe-Q",
    uz: "_jQ_XrpMx1g",
    en: "iuANI0V84Ss",
  };

  return (
    <div className="Grand-logistic-modal-video">
      <div className="container">
        <div className="content">
          <div className="row">
            <div className="col-md-7 col-10">
              <Fade left>
                <div className="commit">
                  {t("grandLogisticModalVideo.commit")}
                </div>
              </Fade>

              <Bounce bottom delay={500}>
                {i18n.language === "ru" ? (
                  <a className="download-btn" href={FileRu} target="_blank">
                    {t("grandLogisticModalVideo.buttonText")}
                  </a>
                ) : i18n.language === "en" ? (
                  <a className="download-btn" href={FileEn} target="_blank">
                    {t("grandLogisticModalVideo.buttonText")}
                  </a>
                ) : (
                  <a className="download-btn" href={FileUz} target="_blank">
                    {t("grandLogisticModalVideo.buttonText")}
                  </a>
                )}
              </Bounce>
            </div>
            <div className="col-md-5 col-2">
              <div className="d-flex h-100 align-items-center justify-content-center flex-column">
                <div className="play-icon-box" onClick={() => setOpen(true)}>
                  <div className="icon-play"></div>
                </div>
              </div>

              <ModalVideo
                allowFullScreen={false}
                xhtml={true}
                color="red"
                channel="youtube"
                autoplay={1}
                isOpen={isOpen}
                videoId={
                  i18n.language === "ru"
                    ? videos.ru
                    : i18n.language === "en"
                    ? videos.en
                    : videos.uz
                }
                onClose={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrandLogisticModalVideo;
