import React, {useState} from 'react';
import "./logistic-hub-process.scss";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const LogisticHubProcess = () => {

    const {t} = useTranslation();

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <div className="Logistic-hub-process">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <Fade left delay={500}>
                            <div className="title">
                                {t("logisticHubProcess.title1")} <br/>
                                {t("logisticHubProcess.title2")} <br/>
                                {t("logisticHubProcess.title3")}
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>

            <div className="accordion-box">

                    <Accordion open={open} toggle={toggle}>
                        <AccordionItem>

                            <div className="container">
                                <AccordionHeader targetId="1"> {t("logisticHubProcess.accordionBox.accordion1.title")}</AccordionHeader>
                                <AccordionBody accordionId="1">
                                    <ul>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion1.info1")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion1.info2")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion1.info3")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion1.info4")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion1.info5")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion1.info6")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion1.info7")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion1.info8")}
                                        </li>
                                    </ul>
                                </AccordionBody>
                            </div>

                        </AccordionItem>
                        <AccordionItem>
                            <div className="container">
                                <AccordionHeader targetId="2">{t("logisticHubProcess.accordionBox.accordion2.title")}</AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <ul>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion2.info1")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion2.info2")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion2.info3")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion2.info4")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion2.info5")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion2.info6")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion2.info7")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion2.info8")}
                                        </li>
                                    </ul>
                                </AccordionBody>
                            </div>
                        </AccordionItem>
                        <AccordionItem>
                            <div className="container">
                                <AccordionHeader targetId="3">{t("logisticHubProcess.accordionBox.accordion3.title")}</AccordionHeader>
                                <AccordionBody accordionId="3">
                                    <ul>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info1")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info2")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info3")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info4")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info5")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info6")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info7")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info8")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info9")}
                                        </li>
                                        <li>
                                            {t("logisticHubProcess.accordionBox.accordion3.info10")}
                                        </li>
                                    </ul>
                                </AccordionBody>
                            </div>
                        </AccordionItem>
                    </Accordion>

            </div>

        </div>
    );
};

export default LogisticHubProcess;