import React from 'react';
import "./green-texnologies.scss";
import Icon1 from "../../assets/images/green-texnologies/icon-1.png";
import Icon2 from "../../assets/images/green-texnologies/icon-2.png";
import Icon3 from "../../assets/images/green-texnologies/icon-3.png";
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const GreenTexnologies = () => {

    const {t} = useTranslation();

    return (
        <div className="Green-texnologies">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <Fade delay={500} left>
                            <div className="title">
                                {t("greenTexnologies.title1")} <br/>
                                {t("greenTexnologies.title2")}
                            </div>
                        </Fade>
                    </div>
                    <div className="col-md-7">
                        <Fade delay={1000} right>
                            <div className="commit">
                                {t("greenTexnologies.commit")}
                            </div>
                        </Fade>
                    </div>
                </div>

                <div className="row green-cards">
                    <Fade left delay={500}>
                        <div className="col-md-4">
                            <div className="card-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info">
                                            <div className="card-title">
                                                <span>{t("greenTexnologies.greenCards.card1.title")}</span>
                                                {t("greenTexnologies.greenCards.card1.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon1} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade bottom delay={500}>
                        <div className="col-md-4">
                            <div className="card-box bg-gradient-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info">
                                            <div className="card-title">
                                                <span>{t("greenTexnologies.greenCards.card2.title")}</span>
                                                {t("greenTexnologies.greenCards.card2.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon2} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade right delay={500}>
                        <div className="col-md-4">
                            <div className="card-box">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="info">
                                            <div className="card-title">
                                                <span>{t("greenTexnologies.greenCards.card3.title")}</span>
                                                {t("greenTexnologies.greenCards.card3.commit")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="img-box">
                                            <img className="img-fluid" src={Icon3} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                </div>
            </div>
        </div>
    );
};

export default GreenTexnologies;