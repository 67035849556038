import React from 'react';
import "./our-contacts.scss";
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import {useTranslation} from "react-i18next";

const OurContacts = () => {

    const {t} = useTranslation();

    return (
        <div className="Our-contacts">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Fade left delay={500}>
                            <div className="title">
                                {t("ourContacts.title")}
                            </div>
                        </Fade>
                        <Fade bottom delay={1000}>
                            <div className="commit">
                                {t("ourContacts.commit")}
                            </div>
                        </Fade>
                    </div>
                </div>

                <div className="row contacts">
                    <div className="col-md-12">
                        <div className="contact-box">
                            <div className="left-side">
                                <Fade left delay={500}>
                                    <div className="contact-title">
                                        {t("ourContacts.contacts.contact1.contactTitle")}
                                    </div>
                                </Fade>
                            </div>
                            <div className="right-side">
                                <Pulse delay={1500}>
                                    <div className="contact">
                                        <a href="tel:+998957678400" className="head">
                                            {t("ourContacts.contacts.contact1.head1")}
                                        </a>
                                        <div className="body">
                                            {t("ourContacts.contacts.contact1.body1")}
                                        </div>
                                    </div>
                                    <div className="contact">
                                        <a href="tel:+998909778549" className="head">
                                            {t("ourContacts.contacts.contact1.head2")}
                                        </a>
                                        <div className="body">
                                            {t("ourContacts.contacts.contact1.body2")}
                                        </div>
                                    </div>
                                </Pulse>
                            </div>
                        </div>
                        <div className="contact-box">
                            <div className="left-side">
                                <Fade left delay={500}>
                                    <div className="contact-title">
                                        {t("ourContacts.contacts.contact2.contactTitle")}
                                    </div>
                                </Fade>
                            </div>
                            <div className="right-side">
                                <Pulse delay={1500}>
                                    <div className="contact">
                                        <a href="mailto:info@grandpharmlogistics.uz" className="head email">
                                            {t("ourContacts.contacts.contact2.head")}
                                        </a>
                                        <div className="body">
                                            {t("ourContacts.contacts.contact2.body")}
                                        </div>
                                    </div>
                                </Pulse>
                            </div>
                        </div>
                        <div className="contact-box">
                            <div className="left-side">
                                <Fade left delay={500}>
                                    <div className="contact-title">
                                        {t("ourContacts.contacts.contact3.contactTitle")}
                                    </div>
                                </Fade>
                            </div>
                            <div className="right-side">
                                <Pulse delay={1500}>
                                    <div className="contact">
                                        <a href="#" className="head map">
                                            {t("ourContacts.contacts.contact3.head1")} <br/>
                                            {t("ourContacts.contacts.contact3.head2")} <br/>
                                        </a>
                                        <div className="body">
                                            {t("ourContacts.contacts.contact3.body")}
                                        </div>
                                    </div>
                                </Pulse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurContacts;