import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "./pages/Home/Home";
import React from "react";
import {ToastContainer} from 'react-toastify';
import About from "./pages/About/About";
import Contacts from "./pages/Contacts/Contacts";
import NotFound from "./pages/NotFound/NotFound";
import TopArrow from "../src/assets/images/arrow-up-white.png";
import {BackTop} from 'antd';
import Services from "./pages/Services/Services";

function App() {

    return (
        <div className="App">

            <Switch>

                <Route exact path="/home" component={Home}/>
                <Route exact path="/about" component={About}/>
                <Route exact path="/services" component={Services}/>
                <Route exact path="/contact" component={Contacts}/>
                <Route exact path="/404" component={NotFound}/>

                <Route exact path="/">
                    <Redirect to="/home"/>
                </Route>

                <Redirect to="/404"/>

            </Switch>

            <BackTop>
                <img src={TopArrow} alt="" className="img-fluid"/>
            </BackTop>

            <ToastContainer
                theme="dark"
                autoClose={2000}
            />

        </div>
    );
}

export default App;
