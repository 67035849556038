import React from 'react';
import "./health-click-away.scss";
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const HealthClickAway = () => {

    const {t} = useTranslation();

    return (
        <div className="Health-click-away">

            <div className="container">
                <div className="row top">
                    <div className="col-md-6">
                        <div className="left-side">
                            <Fade left dealy={500}>
                                <div className="title">
                                    {t("healthClickAway.title1")} <br/>
                                    {t("healthClickAway.title2")}
                                </div>
                            </Fade>
                            <Fade bottom dealy={1000}>
                                <div className="commit">
                                    {t("healthClickAway.commit")}
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="right-side">

                        </div>
                    </div>

                </div>
            </div>

            <div className="info-text-box">
                <div className="container">
                    <div className="info">
                        {t("healthClickAway.info")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthClickAway;