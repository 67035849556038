import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import 'react-toastify/dist/ReactToastify.css';
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Suspense  backback="">
            <App/>
        </Suspense>
    </BrowserRouter>
);

