import React from 'react';
import Navbar from "../../components/Navbar/Navbar";
import HowToOrderServices from "../../components/HowToOrderServices/HowToOrderServices";
import Footer from "../../components/Footer/Footer";
import NotFoundHeader from "../../components/NotFoundHeader/NotFoundHeader";

const NotFound = () => {
    return (
        <>
            <Navbar/>
            <NotFoundHeader/>
            <HowToOrderServices/>
            <Footer/>
        </>
    );
};

export default NotFound;