import React from 'react';
import "./hub-on-the-map.scss";
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const HubOnTheMap = () => {

    const {t} = useTranslation();

    return (
        <div className="Hub-on-the-map">
            <div className="container">
                <Fade bottom delay={500}>
                    <div className="title">
                        {t("hubOnTheMap.title")}
                    </div>
                </Fade>
            </div>
            <div className="map-box">

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d6006.6094838775725!2d69.196394!3d41.17152!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDEwJzE3LjUiTiA2OcKwMTEnNDcuMCJF!5e0!3m2!1sru!2s!4v1685384981234!5m2!1sru!2s"
                    width="600"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />

            </div>
        </div>
    );
};

export default HubOnTheMap;