import React, {useEffect, useState} from 'react';
import Navbar from "../../components/Navbar/Navbar";
import HowToOrderServices from "../../components/HowToOrderServices/HowToOrderServices";
import Footer from "../../components/Footer/Footer";
import ServicesHeader from "../../components/ServicesHeader/ServicesHeader";
import ServicesAndReadySolutions from "../../components/ServicesAndReadySolutions/ServicesAndReadySolutions";
import FullRangeOfServices from "../../components/FullRangeOfServices/FullRangeOfServices";
import GreenTexnologies from "../../components/GreenTexnologies/GreenTexnologies";
import LogisticHubProcess from "../../components/LogisticHubProcess/LogisticHubProcess";
import Loading from "../../components/Loading/Loading";

const Services = () => {

    useEffect(()=>{
        window.scroll(0,0);
    },[]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    if (loading) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <Navbar/>
            <ServicesHeader/>
            <ServicesAndReadySolutions/>
            <FullRangeOfServices/>
            <GreenTexnologies/>
            <LogisticHubProcess/>
            <HowToOrderServices/>
            <Footer/>
        </>
    );
};

export default Services;