import React from 'react';
import "./not-found-header.scss";

const NotFoundHeader = () => {
    return (
        <div className="Not-found-header">
            <div className="container">
                <div className="title">
                    404
                </div>
            </div>
        </div>
    );
};

export default NotFoundHeader;