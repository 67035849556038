import React from 'react';
import "./about-number-of-dorixona.scss";
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const AboutNumberOfDorixona = () => {

    const {t} = useTranslation();

    return (
        <div className="About-number-of-dorixona">

            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="left-side">
                                <div className="top">
                                    <Pulse delay={1000}>
                                        <div className="number-box">
                                            <div className="title">
                                                {t("aboutNumberOfDorixona.numbersBox.number1.title")}
                                            </div>
                                            <div className="commit">
                                                {t("aboutNumberOfDorixona.numbersBox.number1.commit")}
                                            </div>
                                        </div>
                                    </Pulse>
                                </div>
                                <div className="bottom">
                                    <Pulse delay={1500}>
                                        <div className="number-box">
                                            <div className="title">
                                                {t("aboutNumberOfDorixona.numbersBox.number2.title")}
                                            </div>
                                            <div className="commit">
                                                {t("aboutNumberOfDorixona.numbersBox.number2.commit")}
                                            </div>
                                        </div>
                                    </Pulse>
                                    <Pulse delay={1500}>
                                        <div className="number-box">
                                            <div className="title">
                                                {t("aboutNumberOfDorixona.numbersBox.number3.title")}
                                            </div>
                                            <div className="commit">
                                                {t("aboutNumberOfDorixona.numbersBox.number3.commit")}
                                            </div>
                                        </div>
                                    </Pulse>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Fade right delay={500}>
                                <div className="right-side">
                                    <div className="info">
                                        {t("aboutNumberOfDorixona.commit")}
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <div className="info-text-box">
                <div className="container">
                    <div className="info">
                        {t("aboutNumberOfDorixona.info")}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AboutNumberOfDorixona;