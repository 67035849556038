import React, { useState } from "react";
import "./grand-logistic-area.scss";
import Icon1 from "../../assets/images/area-icon/icon-1.png";
import Icon2 from "../../assets/images/area-icon/icon-2.png";
import Icon3 from "../../assets/images/area-icon/icon-3.png";
import Icon4 from "../../assets/images/area-icon/icon-4.png";
import Icon5 from "../../assets/images/area-icon/icon-5.png";
import Icon6 from "../../assets/images/area-icon/icon-6.png";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import PlayIcon from "../../assets/images/modal-video/play-icon.png";
import ModalVideo from "react-modal-video";

const GrandLogisticArea = () => {
  const { t, i18n } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  let videos = {
    ru: "lDEmDhOsB8s",
    uz: "lDEmDhOsB8s",
    en: "lDEmDhOsB8s",
  };

  return (
    <div className="Grand-logistic-area">
      <div className="container">
        <div className="top">
          <div className="row">
            <div className="col-md-12">
              <Fade delay={500} bottom>
                <div className="title">
                  {t("grandLogisticArea.title1")}
                  <br />
                  {t("grandLogisticArea.title2")}
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={500} left>
                <div className="area-info">
                  <div className="number-text">
                    {t("grandLogisticArea.areaInfo.area1.numberText")}
                  </div>
                  <div className="commit-text">
                    {t("grandLogisticArea.areaInfo.area1.commitText")}
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={500} bottom>
                <div className="area-info">
                  <div className="number-text">
                    {t("grandLogisticArea.areaInfo.area2.numberText")}
                  </div>
                  <div className="commit-text">
                    {t("grandLogisticArea.areaInfo.area2.commitText")}
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={500} right>
                <div className="area-info">
                  <div className="number-text">
                    {t("grandLogisticArea.areaInfo.area3.numberText")}
                  </div>
                  <div className="commit-text">
                    {t("grandLogisticArea.areaInfo.area3.commitText")}
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>

        <Fade delay={500} bottom>
          <div className="center">
            <div className="content">
              <div className="left-box">
                <div className="title">{t("grandLogisticArea.forVideo")}</div>
              </div>

              <div className="right-box">
                <div className="d-flex h-100 align-items-center justify-content-center flex-column">
                  <div className="play-icon-box" onClick={() => setOpen(true)}>
                    <div className="icon-play"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>

        <ModalVideo
          allowFullScreen={false}
          xhtml={true}
          color="red"
          channel="youtube"
          autoplay={1}
          isOpen={isOpen}
          videoId={
            i18n.language === "ru"
              ? videos.ru
              : i18n.language === "en"
              ? videos.en
              : videos.uz
          }
          onClose={() => setOpen(false)}
        />

        <div className="bottom">
          <div className="row">
            <div className="col-md-4">
              <Fade delay={500} left>
                <div className="area-info-box">
                  <div className="img-box">
                    <img src={Icon1} alt="" />
                  </div>
                  <div className="title">
                    {t("grandLogisticArea.areaInfoBox.box1.title")}
                  </div>
                  <div className="commit">
                    {t("grandLogisticArea.areaInfoBox.box1.commit")}
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={500} bottom>
                <div className="area-info-box bg-gradient-box">
                  <div className="img-box">
                    <img src={Icon2} alt="" />
                  </div>
                  <div className="title">
                    {t("grandLogisticArea.areaInfoBox.box2.title")}
                  </div>
                  <div className="commit">
                    {t("grandLogisticArea.areaInfoBox.box2.commit")}
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={500} right>
                <div className="area-info-box">
                  <div className="img-box">
                    <img src={Icon3} alt="" />
                  </div>
                  <div className="title">
                    {t("grandLogisticArea.areaInfoBox.box3.title")}
                  </div>
                  <div className="commit">
                    {t("grandLogisticArea.areaInfoBox.box3.commit")}
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={500} left>
                <div className="area-info-box bg-gradient-box">
                  <div className="img-box">
                    <img src={Icon4} alt="" />
                  </div>
                  <div className="title">
                    {t("grandLogisticArea.areaInfoBox.box4.title")}
                  </div>
                  <div className="commit">
                    {t("grandLogisticArea.areaInfoBox.box4.commit")}
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={500} bottom>
                <div className="area-info-box">
                  <div className="img-box">
                    <img src={Icon5} alt="" />
                  </div>
                  <div className="title">
                    {t("grandLogisticArea.areaInfoBox.box5.title")}
                  </div>
                  <div className="commit">
                    {t("grandLogisticArea.areaInfoBox.box5.commit")}
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade delay={500} right>
                <div className="area-info-box bg-gradient-box">
                  <div className="img-box">
                    <img src={Icon6} alt="" />
                  </div>
                  <div className="title">
                    {t("grandLogisticArea.areaInfoBox.box6.title")}
                  </div>
                  <div className="commit">
                    {t("grandLogisticArea.areaInfoBox.box6.commit")}
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrandLogisticArea;
