import React, {useState} from 'react';
import "./how-to-order-services.scss";
import CurrencyFormat from "react-currency-format";
import emailjs from "emailjs-com";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Bounce from 'react-reveal/Bounce';

const HowToOrderServices = () => {

    const {t} = useTranslation();

    const [phone_value, setPhoneValue] = useState('');
    const [name_value, setNameValue] = useState('');
    const [organization_value, setOrganizationValue] = useState('');
    const [loading, setLoading] = useState(false);

    const onSubmit = (e) => {

        setLoading(loading => true);

        e.preventDefault();

        let name = e.target.name.value.length > 0;
        let organization = e.target.organization.value.length > 0;
        let phone = e.target.phone.value.replace(/[^0-9]/g, '').length > 0;

        if (name && phone && organization) {

            emailjs.sendForm(
                'service_m3xrumf',
                'template_j8e7ocr',
                e.target,
                'wqf5nQ9HFXwadl7oa'
            )

                .then((res) => {
                    setNameValue('');
                    setOrganizationValue('');
                    setPhoneValue('');
                    toast.success(t("form.toastify.successMessage"));
                    setLoading(loading => false);
                })

                .catch((err) => {
                    toast.error(t("form.toastify.errorMessage"));
                    setLoading(loading => false);
                })

        } else {
            toast.warning(t("form.toastify.warningMessage"));
            setLoading(loading => false);
        }
    };

    return (
        <div className="How-to-order-services">
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-md-7">

                            <div className="title">
                                {t("howToOrderServices.title1")} <br/>
                                {t("howToOrderServices.title2")}
                            </div>

                            <div className="commit-title">
                                {t("howToOrderServices.commitTitle")}
                            </div>
                            <ul>
                                <li>
                                    {t("howToOrderServices.commit1")}
                                </li>
                                <li>
                                    {t("howToOrderServices.commit2")}
                                </li>
                                <li>
                                    {t("howToOrderServices.commit3")}
                                </li>
                            </ul>

                            <div className="contact-box">
                                <div className="contact">
                                    <div className="head">
                                        {t("howToOrderServices.contacts.contact1.head")}
                                    </div>
                                    <a href="tel:+998909778549" className="body">
                                        {t("howToOrderServices.contacts.contact1.body")}
                                    </a>
                                </div>

                                <div className="contact">
                                    <div className="head">
                                        {t("howToOrderServices.contacts.contact2.head")}
                                    </div>
                                    <a href="mailto:info@grandpharmlogistics.uz" className="body">
                                        {t("howToOrderServices.contacts.contact2.body")}
                                    </a>
                                </div>
                            </div>

                            <div className="work-time">
                                {t("howToOrderServices.workTime")}
                            </div>

                        </div>
                        <div className="col-md-5">
                            <Bounce right delay={500}>
                                <div className="application-box">
                                    <div className="form-title">{t("form.formTitle")}</div>
                                    <form className="application-form" onSubmit={onSubmit}>

                                        <input
                                            value={name_value}
                                            onChange={(e) => setNameValue(e.target.value)}
                                            name="name"
                                            type="text"
                                            placeholder={t("form.placeholderName")}
                                            className=""/>

                                        <input
                                            value={organization_value}
                                            onChange={(e) => setOrganizationValue(e.target.value)}
                                            name="organization"
                                            type="text"
                                            placeholder={t("form.placeholderOrganization")}
                                            className=""/>

                                        <CurrencyFormat
                                            value={phone_value}
                                            onChange={(e) => setPhoneValue(e.target.value)}
                                            id="phone"
                                            name="phone"
                                            format="+### (##) ###-##-##" mask="_"
                                            placeholder={t("form.placeholderPhone")}
                                        />

                                        <button disabled={loading} type="submit" className="form-btn">
                                            {t("form.buttonText")}
                                        </button>

                                    </form>
                                </div>
                            </Bounce>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToOrderServices;